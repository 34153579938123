import { useMemo } from 'react'
import { intersection, uniq } from 'lodash'

import { useSetState } from './use-set-state'
import { useAivyContext } from '../context'
import { colorByString } from '../helpers'
import { SPIDER_CHART_COLORS } from '../constants/spider-chart-colors'

const getColor = (item) => {
  return { ...item, ...colorByString(item.id, SPIDER_CHART_COLORS) }
}

export const useChartData = ({ spaces, careers = [] }) => {
  const { system } = useAivyContext()

  const parsed_dimensions = useMemo(
    () => JSON.parse(system.dimensions.data),
    [system]
  )

  const [state, setState] = useSetState({
    mode: 'LINEAR',
    spaces: spaces.map((s) => ({ ...s, visible: true, ...getColor(s) })),
    dimensions: Object.keys(parsed_dimensions).map((key) => ({
      key,
      ...parsed_dimensions[key],
      visible: true
    })),
    categories: [
      {
        // PERSONALITY
        id: 1,
        visible: true,
        ...system.categories['1'],
        active: true
      },
      {
        // SKILLS
        id: 2,
        visible: true,
        ...system.categories['2'],
        active: false
      },
      {
        // INTERESTS
        id: 3,
        visible: true,
        ...system.categories['3'],
        active: false
      }
    ],
    careers: careers.map((c) => ({ ...c, visible: true, ...getColor(c) }))
  })

  const extractChartData = ({ spaces, dimensions, careers = [] }) => {
    const exams_ids_in_common = intersection(
      ...spaces.map((space) => Object.keys(space.answers)),
      ...careers.map((career) => {
        const challenges = uniq(
          Object.keys(JSON.parse(career.career_analyse).scores)
            .map((dimension) => {
              // for old careers, some dimensions do not exist in the system table
              return parsed_dimensions[dimension]
                ? parsed_dimensions[dimension].exam_id
                : null
            })
            .filter((dimension) => !!dimension)
        )

        return challenges
      })
    )

    let dimensions_in_common = dimensions.filter(({ exam_id }) =>
      exams_ids_in_common.includes(exam_id)
    )

    if (
      careers.find(
        ({ career_analyse }) => !!JSON.parse(career_analyse).career_preset_id
      )
    ) {
      dimensions_in_common = dimensions_in_common.filter(
        ({ key }) => key !== 'RIASEC_IMAGES_elevation'
      )
    }

    // sort dimensions by category
    const sorted_dimensions = [
      ...dimensions_in_common.filter((d) => d.category === 3),
      ...dimensions_in_common.filter((d) => d.category === 1),
      ...dimensions_in_common.filter((d) => d.category === 2)
    ]

    const extractSpaceScore = (space) => {
      const { id, identification, answers, borderColor, backgroundColor } =
        space

      return {
        id,
        label: identification,
        data: sorted_dimensions.map((dimension) => {
          const { exam_id, score } = dimension

          const result = answers[exam_id].aivy_output.translatedzScores[score]

          if (exam_id === 'DELEGATION' && score === 'score_responsibility') {
            return { exam_id, score, result: 100 - result }
          }

          return { exam_id, score, result }
        }),
        borderWidth: 1,
        borderColor,
        backgroundColor
      }
    }

    const extractCareerScore = (career) => {
      const { id, title, career_analyse, borderColor, backgroundColor } = career
      const { scores } = JSON.parse(career_analyse)

      return {
        id,
        label: title,
        data: sorted_dimensions.map((dimension) => {
          const { exam_id, score } = dimension
          const { min, max, optimal } = scores[`${exam_id}_${score}`]

          return { exam_id, score, min, max, optimal }
        }),
        borderWidth: 1,
        borderColor,
        backgroundColor
      }
    }

    return {
      dimensions: sorted_dimensions,
      spaces: spaces.map(extractSpaceScore),
      careers: careers.map(extractCareerScore)
    }
  }

  return {
    data: state,
    setData: setState,
    extractChartData,
    options: {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2,
      scales: {
        r: {
          min: 0,
          max: 100,
          beginAtZero: true,
          angleLines: { display: true },
          grid: { circular: true },
          ticks: { stepSize: 20 }
        }
      },
      plugins: {
        // Disable all animations
        animation: false
      }
    }
  }
}
