/* eslint-disable no-extra-semi */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable one-var */
/* eslint-disable no-undef */
import { useState, useRef } from 'react'
import {
  AdjustmentsHorizontalIcon,
  PuzzlePieceIcon,
  IdentificationIcon
} from '@heroicons/react/20/solid'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  useScroll,
  motion,
  useTransform,
  useMotionValueEvent
} from 'framer-motion'
import PropTypes from 'prop-types'

import DashboardScreenshotTalents from '../assets/images/screenshot-talents.png'
import Passungsprofil from '../assets/images/passungsprofil.png'
import Alex from '../assets/images/landingpage/alex.png'

import IllustrationPersonDesk2 from '../assets/images/illustrations/two-person-desk-2.png'

import IllustrationSelect from '../assets/images/illustrations/select.png'
import HeaderImageAlt from '../assets/images/landingpage/headerimage2-min.png'
import { trackEvent } from '../helpers/analytics'
import { Button } from '../components/tryout/Button'
import { localStorage } from '../helpers/local-storage'
import { Header } from '../components/tryout/Header'
import { Testimonials } from '../components/tryout/Testimonials'
import { Hero } from '../components/tryout/Hero'
import { Container } from '../components/tryout/Container'
import trackEvents from '../constants/track-events'
import Modal from '../components/tailwind/Modal'
import { Pricing } from '../components/tryout/Pricing'
import { useTranslation } from 'react-i18next'
import { HeaderHint } from '../components/tryout/HeaderHint'
import clsx from 'clsx'

const Tryout = ({ lang }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const { scrollYProgress, scrollY } = useScroll()
  const scale = useTransform(scrollYProgress, [0.2, 0.4], [0.5, 1.1])
  const [isYoutubeOverlayVisible, setIsYoutubeOverlayVisible] = useState(false)
  const containerRef = useRef(null)
  const [shrink, setShrink] = useState(false)

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (latest > 130) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  })

  const navigate = useNavigate()

  const navigateToFunnel = () => {
    const ausprobieren = localStorage.getItem('ausprobieren')

    navigate(`/ausprobieren/${ausprobieren || 'new'}`, {
      state: { searchParams: searchParams.toString() }
    })
  }

  const features = [
    {
      name: t('landingpage.feature_section.features.01.title'),
      description: t('landingpage.feature_section.features.01.description'),
      icon: AdjustmentsHorizontalIcon
    },
    {
      name: t('landingpage.feature_section.features.02.title'),
      description: t('landingpage.feature_section.features.02.description'),
      icon: PuzzlePieceIcon
    },
    {
      name: t('landingpage.feature_section.features.03.title'),
      description: t('landingpage.feature_section.features.03.description'),
      icon: IdentificationIcon
    }
  ]

  const footerNavigation = {
    main: [
      {
        name: t('landingpage.footer.imprint'),
        href: 'https://www.aivy.app/impressum'
      },
      {
        name: t('landingpage.footer.data_policy'),
        href: 'https://www.aivy.app/datenschutz/'
      }
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/aivyapp',
        icon: (props) => (
          <svg
            fill='currentColor'
            role='img'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
          >
            <title>Facebook</title>
            <path
              fillRule='evenodd'
              d='M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z'
            />
          </svg>
        )
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/aivy.app/',
        icon: (props) => (
          <svg
            fill='currentColor'
            role='img'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
          >
            <title>Instagram</title>
            <path d='M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z' />
          </svg>
        )
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/aivyapp',
        icon: (props) => (
          <svg
            role='img'
            fill='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
          >
            <title>Twitter</title>
            <path d='M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z' />
          </svg>
        )
      },
      {
        name: 'YouTube',
        href: 'https://www.youtube.com/channel/UCraNBOrc6VH2z8sS-nEir8Q/',
        icon: (props) => (
          <svg
            role='img'
            fill='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
          >
            <title>YouTube</title>
            <path d='M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z' />
          </svg>
        )
      },
      {
        name: 'Xing',
        href: 'https://www.xing.com/pages/aivy',
        icon: (props) => (
          <svg
            role='img'
            fill='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
          >
            <title>Xing</title>
            <path d='M18.188 0c-.517 0-.741.325-.927.66 0 0-7.455 13.224-7.702 13.657.015.024 4.919 9.023 4.919 9.023.17.308.436.66.967.66h3.454c.211 0 .375-.078.463-.22.089-.151.089-.346-.009-.536l-4.879-8.916c-.004-.006-.004-.016 0-.022L22.139.756c.095-.191.097-.387.006-.535C22.056.078 21.894 0 21.686 0h-3.498zM3.648 4.74c-.211 0-.385.074-.473.216-.09.149-.078.339.02.531l2.34 4.05c.004.01.004.016 0 .021L1.86 16.051c-.099.188-.093.381 0 .529.085.142.239.234.45.234h3.461c.518 0 .766-.348.945-.667l3.734-6.609-2.378-4.155c-.172-.315-.434-.659-.962-.659H3.648v.016z' />
          </svg>
        )
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/aivyapp/',
        icon: (props) => (
          <svg
            role='img'
            fill='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
          >
            <title>LinkedIn</title>
            <path d='M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z' />
          </svg>
        )
      }
    ]
  }

  return (
    <div className='bg-white' ref={containerRef}>
      <HeaderHint />

      <div
        className={clsx(
          'sticky top-0 backdrop-blur-lg bg-white/70 z-40',
          shrink && 'border-b'
        )}
      >
        <Header
          onPress={() => {
            trackEvent(trackEvents.CLICK_NAV_STARTNOW_LANDINGPAGE)
            navigateToFunnel()
          }}
          shrink={shrink}
        />
      </div>
      <main>
        <Hero
          onPress={() => {
            trackEvent('CTA_First_TryOut')
            navigateToFunnel()
          }}
          onPressVideo={() => {
            trackEvent(trackEvents.CLICK_HERO_YOUTUBE_LANDINGPAGE)
            setIsYoutubeOverlayVisible(true)
          }}
        />

        <div className='relative overflow-hidden'>
          <div className='bg-slate-50 py-20 sm:py-32'>
            <div className='lg:px-8 lg:mx-auto lg:max-w-7xl px-8'>
              <div className='lg:grid lg:grid-cols-2 lg:gap-x-16 '>
                <div className='flex flex-col'>
                  <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
                    {t('landingpage.cta_sections.first.title')}
                  </h2>
                  <p className='mt-4 text-lg text-gray-700'>
                    {t('landingpage.cta_sections.first.sub_title')}
                  </p>
                  <div className='mt-4 flex items-center'>
                    <Button
                      onClick={() => {
                        trackEvent('CTA_Second_TryOut')
                        navigateToFunnel()
                      }}
                      color='blue'
                    >
                      <span>
                        {t('landingpage.cta_sections.first.cta_button')}
                      </span>
                    </Button>
                  </div>
                </div>
                <div
                  style={{ height: 1 }}
                  className='lg:hidden mx-24 my-12 bg-gray-300'
                />
                <div className='flex flex-col sm:flex-row  gap-x-6 md:gap-x-10'>
                  <img src={Alex} className='rounded-full h-40 w-40 mb-6' />

                  <div>
                    <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
                      {t('landingpage.cta_sections.second.title')}
                    </h2>
                    <p className='mt-4 text-lg text-gray-700'>
                      {t('landingpage.cta_sections.second.sub_title')}
                    </p>
                    <div className='mt-6'>
                      <Button
                        target='_blank'
                        rel='noopener noreferrer'
                        to='https://calendly.com/aivy-app/einblicke-in-aivy'
                        onClick={() => {
                          trackEvent('CTA_Third_TryOut')
                        }}
                        color='blue'
                      >
                        <span>
                          {t('landingpage.cta_sections.second.cta_button')}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section id='features' className='py-20 sm:py-32'>
            <Container
              className={
                'flex flex-col md:flex-row justify-between items-center'
              }
            >
              <div>
                <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 w-full md:w-8/12'>
                  {t('landingpage.benefits_section.title')}
                </h2>

                <div className='px-4 max-w-xl'>
                  <div>
                    <ul className='mt-6 text-lg text-gray-700 list-disc ml-5'>
                      <li className='mb-2'>
                        {t('landingpage.benefits_section.benefits.01.first')}{' '}
                        <strong className='text-blue-600'>
                          {t('landingpage.benefits_section.benefits.01.second')}
                        </strong>{' '}
                        {t('landingpage.benefits_section.benefits.01.third')}
                      </li>
                      <li className='mb-2'>
                        {t('landingpage.benefits_section.benefits.02.first')}{' '}
                        <strong className='text-blue-600'>
                          {t('landingpage.benefits_section.benefits.02.second')}
                        </strong>
                      </li>
                      <li className='mb-2'>
                        {t('landingpage.benefits_section.benefits.03.first')}{' '}
                        <strong className='text-blue-600'>
                          {t('landingpage.benefits_section.benefits.03.second')}
                        </strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={IllustrationPersonDesk2}
                  className='h-auto w-96 mb-4'
                />
              </div>
              <div className='relative'>
                <iframe
                  onClick={() => trackEvent('VideoPlayed_TryOut')}
                  // height='315'
                  className='rounded-xl relative mt-8 md:mt-0 w-full md:w-560 h-80 z-30'
                  src={
                    lang === 'en'
                      ? 'https://www.youtube.com/embed/BFjaZFWbi90?si=6GLvCF_jfKaMsggk&amp;controls=0'
                      : 'https://www.youtube-nocookie.com/embed/tkuGshXIfgU?si=N-lOoUS-JK6L9lq4&amp;controls=0'
                  }
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                />
                <img
                  src={IllustrationSelect}
                  style={{ zIndex: 0 }}
                  className='w-52 h-auto absolute -bottom-24 -right-24'
                />
              </div>
            </Container>
          </section>

          <div className='overflow-hidden bg-white py-24 sm:py-32'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
              <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
                <div className='lg:pr-8 lg:pt-4'>
                  <div className='lg:max-w-lg'>
                    <h2 className='text-base font-semibold leading-7 text-blue-600'>
                      Aivy Dashboard
                    </h2>
                    <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                      {t('landingpage.feature_section.title')}
                    </p>
                    <p className='mt-6 text-lg leading-8 text-gray-600'>
                      {t('landingpage.feature_section.sub_title')}
                    </p>
                    <dl className='mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none'>
                      {features.map((feature) => (
                        <div key={feature.name} className='relative pl-9'>
                          <dt className='inline font-semibold text-gray-900'>
                            <feature.icon
                              className='absolute left-1 top-1 h-5 w-5 text-blue-600'
                              aria-hidden='true'
                            />
                            {feature.name}
                          </dt>{' '}
                          <dd className='inline'>{feature.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
                <div className='relative'>
                  <img
                    src={DashboardScreenshotTalents}
                    alt='Product screenshot'
                    className='w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 brightness-90'
                    width={2432}
                    height={1442}
                  />
                  <motion.img
                    src={Passungsprofil}
                    style={{ scale }}
                    className='absolute w-[30rem] left-52 -bottom-16 hidden lg:flex'
                  />
                </div>
              </div>
            </div>
            <div className='bg-white'>
              <div className='px-6 pt-12 sm:px-6  lg:px-8'>
                <div className='mx-auto max-w-2xl text-center'>
                  <div className='mt-10 flex items-center justify-center gap-x-6'>
                    <Button
                      onClick={() => {
                        trackEvent(
                          trackEvents.CLICK_FEATURES_TRYSELF_LANDINGPAGE
                        )
                        navigateToFunnel()
                      }}
                      color='blue'
                    >
                      <span>{t('landingpage.feature_section.cta')}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonials />
        <Pricing />
        <section>
          <div className='relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-48 lg:px-8'>
            <img
              src={HeaderImageAlt}
              alt=''
              className='absolute inset-0 -z-10 h-full w-full object-cover brightness-50'
            />
            <div className='mx-auto max-w-3xl text-center'>
              <h2 className='text-4xl font-bold tracking-tight text-white sm:text-5xl text-center'>
                {t('landingpage.footer_cta_section.title')}
              </h2>
              <div className='mt-10 flex items-center gap-x-6 justify-center'>
                <Button
                  onClick={() => {
                    trackEvent('CTA_Footer_TryOut')
                    navigateToFunnel()
                  }}
                  color='blue'
                >
                  <span>{t('landingpage.footer_cta_section.cta')}</span>
                </Button>
              </div>
              <div className='mt-8 grid grid-cols-2 grid-rows-2 md:grid-cols-4 md:grid-rows-1 gap-2 max-w-3xl m-auto'>
                <div className='flex flex-row'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6 text-white'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
                    />
                  </svg>
                  <span className='text-white font-bold ml-2'>
                    {t('landingpage.hero.satisfaction')}
                  </span>
                </div>
                <div className='flex flex-row'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6 text-white'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z'
                    />
                  </svg>
                  <span className='text-white font-bold ml-2'>
                    {t('landingpage.hero.time_saving')}
                  </span>
                </div>
                <div className='flex flex-row'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6 text-white'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
                    />
                  </svg>
                  <span className='text-white font-bold ml-2'>
                    {t('landingpage.hero.dsgvo')}
                  </span>
                </div>
                <div className='flex flex-row'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6 text-white'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z'
                    />
                  </svg>
                  <span className='text-white font-bold ml-2 text-left'>
                    {t('landingpage.hero.no_creditcard')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className='bg-white'>
        <div className='max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8'>
          <nav
            className='-mx-5 -my-2 flex flex-wrap justify-center'
            aria-label='Footer'
          >
            {footerNavigation.main.map((item) => (
              <div key={item.name} className='px-5 py-2'>
                <a
                  href={item.href}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='inline-block rounded-lg px-2 py-1 text-base  text-slate-700 hover:bg-slate-100 hover:text-slate-900'
                >
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
          <p className='mt-8 text-center text-base text-gray-700'>
            {`© ${new Date().getFullYear()} Aivy GmbH`}
          </p>
        </div>
      </footer>

      <Modal
        open={isYoutubeOverlayVisible}
        setOpen={setIsYoutubeOverlayVisible}
        size={'3xl'}
      >
        <iframe
          src='https://www.youtube-nocookie.com/embed/h0cvtbwQAIk?si=AoTmptl9RSEez7-B&amp;controls=0&autoplay=1'
          title='Aivy in 120 Sekunden'
          className='w-full h-96 mt-6'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        />
      </Modal>
    </div>
  )
}

Tryout.propTypes = {
  lang: PropTypes.string.isRequired
}

export default Tryout
