import { createContext, useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { isNumber } from 'lodash'
import { formatDistance } from 'date-fns'
import { de } from 'date-fns/locale'

import { useUserContext } from './user-context'
import { sendSlackMessage } from '../helpers'
import { setRestrictions as setRestrictionsSingleton } from '../helpers/restrictions'
import Paywall from '../components/paywall'
import { usePartnerContext } from './partner-context'

const PaymentContext = createContext()

PaymentContext.displayName = 'PaymentContext'

export function PaymentContextProvider({ payments, children }) {
  const paywallRef = useRef()

  const { isAdmin } = useUserContext()

  const { partner } = usePartnerContext()

  const value = useMemo(() => {
    const showPaywall = (paywall) => {
      paywallRef.current.openPaywall({ paywall, type: 'modal' })
    }

    const newestPayment = payments
      // filter unused_campaign_credits payments
      .filter(
        ({ unused_campaign_credits }) => !isNumber(unused_campaign_credits)
      )
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .pop()

    const ACCESS_EXPIRED = { accessExpired: true }

    if (!newestPayment) return ACCESS_EXPIRED

    const now = Date.now()
    const { start_at, expires_at, partner_id, is_trial } = newestPayment

    if (
      // now is not between start_at and expires_at
      !(Date.parse(start_at) < now && now < Date.parse(expires_at))
    ) {
      if (is_trial) return ACCESS_EXPIRED

      const message = `Zahlung von ${
        partner.name || partner.display_name
      } ist seit ${formatDistance(new Date(expires_at), new Date(), {
        locale: de
      })} abgelaufen. Jetzt aktuelle Daten im Aivy OS hinterlegen: https://www.os.aivy.app/customer/${partner_id}`
      !isAdmin && sendSlackMessage('vertrieb', message)

      // return ACCESS_EXPIRED
    }

    // the user has access to the dashboard as long as a payment exists with
    // !(Date.parse(start_at) < now && now < Date.parse(expires_at))

    const unusedCampaignCredits = payments
      .filter(({ unused_campaign_credits }) =>
        isNumber(unused_campaign_credits)
      )
      .map(({ unused_campaign_credits }) => unused_campaign_credits)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

    // possible products
    // ATTRACT
    // SMARTPREDICT
    // FAKINGMODULE
    // INTERVIEWPAPER
    // BROWSERPLUGIN
    // REDIRECTTALENTURL
    // CROSSCOMPARISON
    // INTEGRATIONS
    // AUTOMATIONS

    // the dashboard uses the following products
    // ATTRACT
    // REDIRECTTALENTURL
    // CROSSCOMPARISON
    // INTEGRATIONS
    // AUTOMATIONS

    // the dashboard uses the following payment restrictions
    const { products, seat_count, career_count, spaces_count } = newestPayment

    const result = {
      ...newestPayment,
      accessExpired: false,
      is_trial: !!newestPayment.is_trial,
      seat_count,
      career_count,
      spaces_count,
      attract: (products || []).includes('ATTRACT'),
      redirectTalentUrl: (products || []).includes('REDIRECTTALENTURL'),
      crossComparison: (products || []).includes('CROSSCOMPARISON'),
      integrations: is_trial
        ? false
        : (products || []).includes('INTEGRATIONS'),
      automations: (products || []).includes('AUTOMATIONS'),
      showPaywall,
      unusedCampaignCredits
    }

    setRestrictionsSingleton(result)

    return result
  }, [payments, isAdmin, partner])

  return (
    <>
      <Paywall ref={paywallRef} />
      <PaymentContext.Provider value={value}>
        {children}
      </PaymentContext.Provider>
    </>
  )
}

PaymentContextProvider.propTypes = {
  payments: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired
}

export function usePaymentContext() {
  const context = useContext(PaymentContext)

  if (!context) {
    throw new Error('usePaymentContext must be used within a AppProvider')
  }

  return context
}
